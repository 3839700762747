import React, { useState } from "react";
import Layout from "../components/Layout"; // Adjust the path as necessary
import { FaChevronUp, FaCircle } from "react-icons/fa"; // Assuming you use React Icons for the circle
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

function About() {
  const [activeIndex, setActiveIndex] = useState(0);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const achievements = [
    {
      title: "Challenging",
      years: "2023",
      description: `Our team achieved second place in a competitive NASA AI contest, standing out among 300 teams. This experience was a testament to our collaborative innovation, problem-solving capabilities, and dedication.`,
      link: "https://drivendata.co/blog/ai-assistants-winners",
    },
    {
      title: "Educational",
      years: "2024",
      description: `I developed interactive web simulators intended to break down neuroscience concepts. These tools became engaging for students wanting to explore neuroscience in a practical way. The led to an unexpected opportunity: creating neuron model visualizations for an academic course, combining educational innovation with real-world application.`,
      link: "https://neuropractical.web.app/eeg-simulator",
    },
    {
      title: "Dynamic",
      years: "2023",
      description: `I led a project that captured the attention of 150,000 users in 48 hours, merging legal education with user-friendly technology. This initiative demanded strong project management skills, effective communication, and a clear vision to deliver impactful solutions within tight deadlines.`,
      link: "https://www.linkedin.com/posts/itayinbardev_%D7%90%D7%99%D7%9A-%D7%9E%D7%91%D7%99%D7%90%D7%99%D7%9D-%D7%9E%D7%95%D7%93%D7%A2%D7%95%D7%AA-%D7%97%D7%91%D7%A8%D7%AA%D7%99%D7%AA-%D7%90%D7%9C-150000-%D7%99%D7%A9%D7%A8%D7%90%D7%9C%D7%99%D7%9D-activity-7068622939063017472-ix_B?utm_source=share&utm_medium=member_desktop",
    },
  ];

  return (
    <Layout>
      <div className="flex justify-center items-center pt-20 lg:pt-32">
        <div className="max-w-4xl w-full">
          {/* 1. Title */}
          <h1 className="text-4xl font-bold text-deepPink font-jetbrains">
            I'm Itay
          </h1>
          {/* 2. Bolded Text */}
          <p className="mt-3 font-bold text-darkblue  font-jetbrains">
            and I'm passionate on exploring how our brains tick and how tech can
            tag along.
          </p>
          {/* 3. Regular Text */}
          <p className="mt-3 text-darkblue">
            Getting into how the brain works and mixing it with tech is my kind
            of adventure. I am dedicated to discovering innovative ways
            technology and neuroscience can come together to unlock some of
            life’s mysteries.{" "}
          </p>
          <p className="mt-3 text-darkblue font-bold">
            My journey is all about teamwork & creative problem-solving.
          </p>{" "}
          <p className="text-darkblue">
            From leading a project to brainstorming with buddies, I bring
            enthusiasm and a moment for breaking down complex ideas into fun,
            understandable bits.
          </p>
          <p className="mt-3 text-darkblue">
            I research at{" "}
            <a
              href="https://brainsandmachines.org/"
              target="_blank"
              className="text-deepPink"
              rel="noreferrer"
            >
              Brains & Machines Lab
            </a>
            , and work at{" "}
            <a
              href="http://www.brainqtech.com"
              target="_blank"
              className="text-deepPink"
              rel="noreferrer"
            >
              BRAIN.Q
            </a>
            .
          </p>
          {/* 4. Circle and Degree Info */}
          <div className="flex flex-col sm:flex-row sm:gap-6">
            <div className="flex items-center mt-5">
              <FaCircle className="text-slategray" /> {/* Circle Icon */}
              <div className="ml-4 text-darkblue">
                <p className="mt-2 text-sm font-bold">
                  B.Sc. Computational Neuroscience
                </p>
                <p className="mt-2 text-sm">
                  Ben Gurion University of the Negev
                </p>
                <p className="mt-3 text-sm text-lightgray">2021-2024</p>
              </div>
            </div>
            <div className="flex items-center mt-5">
              <FaCircle className="text-slategray" /> {/* Circle Icon */}
              <div className="ml-4 text-darkblue">
                <p className="mt-2 text-sm font-bold">
                  M.A Computational Neuroscience
                </p>
                <p className="mt-2 text-sm">
                  Ben Gurion University of the Negev
                </p>
                <p className="mt-3 text-sm text-lightgray">2024-2026</p>
              </div>
            </div>
          </div>
          {/* Horizontal Line */}
          <hr className="border-deepPink border-1 w-12/12 mt-5" />
          {/* 5. Achievements Title */}
          <h2 className="mt-8 text-2xl font-bold text-deepPink  font-jetbrains">
            Achievements & Highlights
          </h2>
          {/* Achievements Content Here */}
          {/* Achievements List */}
          <div className="mt-5">
            {achievements.map((achievement, index) => (
              <div key={index} className="mt-5">
                {/* Accordion header */}
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => toggleAccordion(index)}
                >
                  <p className="flex-1 text-darkblue">
                    {" "}
                    <span className="font-bold text-deepPink">
                      {achievement.title}
                    </span>{" "}
                    Experience
                  </p>
                  {activeIndex === index ? (
                    <FaChevronUp className="text-darkblue" />
                  ) : (
                    <FaChevronDown className="text-darkblue" />
                  )}
                </div>

                {/* Accordion content */}
                <div
                  className={` mt-2 overflow-hidden transition-all duration-500 ${
                    activeIndex === index ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <p className="text-sm text-slategray">{achievement.years}</p>
                  <p className="text-darkblue">{achievement.description}</p>
                  {achievement.link && (
                    <a
                      href={achievement.link}
                      className="text-deepPink text-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pics or it didn't happen{" "}
                      <FaChevronRight className="inline-block text-xs" />
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-16 pb-20 flex justify-center">
            <a
              href="/contact"
              className="font-jetbrains border border-deepPink text-white bg-deepPink uppercase px-4 py-2 rounded-sm transition-colors duration-300 hover:bg-white hover:text-deepPink"
            >
              Let's Talk
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
